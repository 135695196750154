import React from 'react';
import './styles/index.scss';
import backImg from '../images/nft 1.png';
import './styles/coming-soon.css';
import { Container, Row, Col } from 'react-bootstrap';
import Countdown from 'react-countdown';
import { graphql } from 'gatsby'
import Seo from '../components/seo/seo';

const ComingSoon = ({ data }) => {

    const expired = new Date(data.allDatoCmsSoon.nodes[0].date)

    return (
        <div className="background-theme">
            <div className='coming-soon'>
                <div className='background-image'>
                    <img alt="" src={backImg}></img>
                </div>
                <div className="max-height-width">
                    <Container>
                        <Row>
                            <Col lg={5}>
                                <div className='title-count'>
                                    <h3>
                                        <div dangerouslySetInnerHTML={{ __html: data.allDatoCmsSoon.nodes[0].title }}></div>
                                    </h3>
                                    <Countdown date={expired}
                                        renderer={renderer} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return <div className='d-flex'>
        <div>
            <span>{days}</span>
            <hr></hr>
            <span>DAYS</span>
        </div>
        <div>
            <span>{hours}</span>
            <hr></hr>
            <span>HOURS</span>
        </div>
        <div>
            <span>{minutes}</span>
            <hr></hr>
            <span>MINUTES</span>
        </div>
        <div>
            <span>{seconds}</span>
            <hr></hr>
            <span>SECONDS</span>
        </div>
    </div>
};

export default ComingSoon

export const query = graphql`
query ComingSoonQuery {
    allDatoCmsSoon {
        nodes {
            title
            date(formatString: "MM/DD/YYYY")
          seoMetaTags {
            tags
          }
        }
      }
  }
`

export const Head = ({ data }) => (
    <Seo
        title="Coming Soon"
        description="Collect • Play • Trade"
        image="https://www.datocms-assets.com/80384/1664752762-dtc-logo-2021.png?auto=format"
    />
)

